// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Prudentheed Stocks</h1>
    </div>
  )
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// class Stock {
//   constructor(ticker, price) {
//     this.ticker = ticker;
//     this.price = price;
//   }
// 
//   priceString() {
//     return this.ticker + "'s price is " + this.price;
//   }
// }

// const appl = new Stock("AAPL");

export default App;
